const _temp0 = require("url:./arranging_09.json");
const _temp1 = require("url:./constructivist_08.json");
const _temp2 = require("url:./corners_04.json");
const _temp3 = require("url:./cyrillic_04.json");
const _temp4 = require("url:./greek_04.json");
const _temp5 = require("url:./latin_03.json");
const _temp6 = require("url:./loader.json");
const _temp7 = require("url:./mono_07.json");
const _temp8 = require("url:./pressure_04.json");
const _temp9 = require("url:./shippingbox_05.json");
module.exports = {
  "arranging_09.json": _temp0,
  "constructivist_08.json": _temp1,
  "corners_04.json": _temp2,
  "cyrillic_04.json": _temp3,
  "greek_04.json": _temp4,
  "latin_03.json": _temp5,
  "loader.json": _temp6,
  "mono_07.json": _temp7,
  "pressure_04.json": _temp8,
  "shippingbox_05.json": _temp9
}