import { theme } from "../../../tailwind.config";
import { parseFontVariationSettings } from "../util/lib";

const FamilyOverviewTable = (el) => {
  const isTouch =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  const cells = [...el.querySelectorAll("td")];

  const setDataAttributes = () => {
    cells.forEach((cell, i) => {
      const computedStyle = window.getComputedStyle(cell);
      const fontVariationSettings = computedStyle.getPropertyValue(
        "font-variation-settings"
      );
      const fontFamily = computedStyle.getPropertyValue("font-family");
      const { wght, ital, wdth } = parseFontVariationSettings(
        fontVariationSettings
      );
      cell.dataset.wght = wght;
      cell.dataset.wdth = wdth;
      cell.dataset.ital = ital;
      cell.dataset.fontFamily = fontFamily;
    });
  };

  const onMouseEnterCell = (_e) => {
    const e = _e.touches ? _e.touches[0] : _e;
    el.classList.add("active");
    const { wght, wdth, ital, fontFamily } = e.target.dataset;
    cells.forEach((cell) => {
      cell.style.setProperty("--font-variation-wght", wght);
      cell.style.setProperty("--font-variation-wdth", wdth);
      cell.style.setProperty("--font-variation-ital", ital);
      cell.style.setProperty("--font-family", fontFamily);
    });
  };

  const onMouseLeaveCell = (e) => {
    el.classList.remove("active");
    e.target.classList.remove("js-family-overview-cell-hover");
    // el.style.setProperty("--font-variation-settings", "");

    cells.forEach((cell) => {
      cell.style.setProperty("--font-variation-wght", "");
      cell.style.setProperty("--font-variation-wdth", "");
      cell.style.setProperty("--font-variation-ital", "");
      cell.style.setProperty("--font-family", "");
    });
  };

  cells.forEach((cell) => {
    if (isTouch) {
      cell.addEventListener("touchstart", onMouseEnterCell);
      cell.addEventListener("touchend", onMouseLeaveCell);
    } else {
      cell.addEventListener("mouseenter", onMouseEnterCell);
      cell.addEventListener("mouseleave", onMouseLeaveCell);
    }
  });
  window.addEventListener("touchend", () => setTimeout(onMouseLeaveCell, 0));

  setDataAttributes();
};

export default FamilyOverviewTable;
