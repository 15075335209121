import lottie from "lottie-web";
import * as animationSrcs from "url:../../animations/*";

const LottiePlayer = (el, { onEnterFrame } = {}) => {
  let path = animationSrcs[el.dataset.src];

  // uncomment to use test json asset from GT Planar site
  // path = animationSrcs["TEST.json"];

  let player;
  const init = () => {
    player = lottie.loadAnimation({
      container: el,
      renderer: "svg",
      loop: true,
      autoplay: false,
      path: animationSrcs[el.dataset.src],
    });
    if (onEnterFrame) {
      player.addEventListener("enterFrame", onEnterFrame);
    }
  };

  const onInViewChange = (entries) => {
    if (entries[0].isIntersecting) {
      player.play();
    } else {
      player.pause();
    }
  };

  const inViewObserver = new IntersectionObserver(onInViewChange, {
    root: null,
  });

  init();
  inViewObserver.observe(el);
};
export default LottiePlayer;
