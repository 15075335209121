{
  "mono": [
    { "text": "National Wooden Pallet and Container Association", "wdth": 100, "wght": 900, "ital": 1 },
    {
      "text": "ST-0219 REUSABLE POLYETHYLENE STENCIL",
      "wdth": 100,
      "wght": 300,
      "ital": 0
    },
    {
      "text": "DO NOT FREEZE",
      "wdth": 100,
      "wght": 800,
      "ital": 1
    },
    {
      "text": "Live Plants Please Rush",
      "wdth": 150,
      "wght": 500,
      "ital": 0
    },
    {
      "text": "Labels help prevent damage.",
      "wdth": 150,
      "wght": 400,
      "ital": 0
    },
    {
      "text": "ECONOMY STORAGE FILE BOX",
      "wdth": 150,
      "wght": 700,
      "ital": 0
    },
    {
      "text": "Конте́йнер",
      "wdth": 150,
      "wght": 700,
      "ital": 0
    },
    {
      "text": "Контейнеровоз перевозит до 11 тысяч TEU",
      "wdth": 150,
      "wght": 500,
      "ital": 0
    },
    {
      "text": "Контейнери за насипни товари",
      "wdth": 150,
      "wght": 300,
      "ital": 0
    },
    {
      "text": "Ξηρά φορτία",
      "wdth": 150,
      "wght": 300,
      "ital": 0
    },
    {
      "text": "Τα πλοία που μεταφέρουν έτοιμα συσκευασμένα φορτία",
      "wdth": 150,
      "wght": 700,
      "ital": 0
    }
  ],
  "proportional": [
    { "text": "Trong mỗi phương", "wdth": 150, "wght": 700, "ital": 0 },
    {
      "text": "Εγκαταστάσεις",
      "wdth": 150,
      "wght": 900,
      "ital": 0
    },
    {
      "text": "Контейнер",
      "wdth": 100,
      "wght": 500,
      "ital": 0
    },
    {
      "text": "Фітингова платформа",
      "wdth": 100,
      "wght": 300,
      "ital": 0
    },
    {
      "text": "CARGO PORT",
      "wdth": 100,
      "wght": 400,
      "ital": 1
    },
    {
      "text": "CORRUGATED STEEL",
      "wdth": 100,
      "wght": 700,
      "ital": 0
    },
    {
      "text": "Liverpool and Manchester Railway",
      "wdth": 100,
      "wght": 500,
      "ital": 0
    },
    {
      "text": "Intermodal Container",
      "wdth": 150,
      "wght": 300,
      "ital": 0
    },
    {
      "text": "Kazimir Malevich",
      "wdth": 100,
      "wght": 900,
      "ital": 0
    },
    {
      "text": "Малевич Казимир Северинович",
      "wdth": 150,
      "wght": 700,
      "ital": 0
    },
    {
      "text": "SCHREIBBAND",
      "wdth": 100,
      "wght": 500,
      "ital": 0
    }
  ]
}
