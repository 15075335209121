import { parseFontVariationSettings } from "../util/lib";

const IntroStyleOverview = (el) => {
  const samples = [
    ...el.querySelectorAll("[data-component='intro-style-overview__sample']"),
  ];

  const styles = [
    ...el.querySelectorAll("[data-component='intro-style-overview__style']"),
  ];

  styles.forEach((styleEl) => {
    const computedStyle = window.getComputedStyle(styleEl);
    const variationSettings = computedStyle.getPropertyValue(
      "font-variation-settings"
    );
    const { wght, ital } = parseFontVariationSettings(variationSettings);
    styleEl.dataset.wght = wght;
    styleEl.dataset.ital = ital;

    const onMouseEnter = () => {
      const { wght, ital } = styleEl.dataset;
      el.dataset.ital = ital;
      el.dataset.wght = wght;
      samples.forEach((sampleEl) => {
        sampleEl.style.setProperty("--font-variation-wght", wght);
        sampleEl.style.setProperty("--font-variation-ital", ital);
      });
    };

    const onMouseLeave = () => {
      el.dataset.ital = "";
      el.dataset.wght = "";
      samples.forEach((sampleEl) => {
        sampleEl.style.setProperty("--font-variation-wght", "");
        sampleEl.style.setProperty("--font-variation-ital", "");
      });
    };
    styleEl.addEventListener("mouseenter", onMouseEnter);
    styleEl.addEventListener("mouseleave", onMouseLeave);
    styleEl.addEventListener("touchstart", onMouseEnter);
    styleEl.addEventListener("touchend", onMouseLeave);
  });
};

export default IntroStyleOverview;
