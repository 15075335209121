import LottiePlayer from "./components/LottiePlayer";
import { debounce, delay } from "lodash";
import fontSrcs from "url:../fonts/*";
import Section from "./components/Section";
import CharSet from "./components/CharSet";
import Nav from "./components/Nav";
import IntroStyleOverview from "./components/IntroStyleOverview";
import FamilyOverviewTable from "./components/FamilyOverviewTable";
import AnimatedWidth from "./components/AnimatedWidth";
import CSSAnimatedElement from "./components/CSSAnimatedElement";
import ExtendedSubfamilyAnimation from "./components/ExtendedSubfamilyAnimation";
import { delay, isSafari } from "./util/lib";
import TypeTester from "./components/TypeTester";

const onResize = debounce(() => {
  requestAnimationFrame(() => {
    document.documentElement.style.setProperty(
      "--visible-viewport-height",
      `${window.innerHeight}px`
    );
  });
}, 111);

const loadFonts = async () => {
  const gtPressuraProportional = new FontFace(
    "GT Pressura Proportional",
    `url("${fontSrcs["GT-Pressura-VF.woff2"]}") format("woff2-variations"), url("${fontSrcs["GT-Pressura-VF.ttf"]}") format("truetype-variations")`,
    {
      style: "normal",
    }
  );
  const gtPressuraMono = new FontFace(
    "GT Pressura Mono",
    `url("${fontSrcs["GT-Pressura-Mono-VF.woff2"]}") format("woff2-variations"), url("${fontSrcs["GT-Pressura-Mono-VF.ttf"]}") format("truetype-variations")`,
    {
      style: "normal",
    }
  );
  // Trying to add new subsetted fonts to this file here myself, please check if I did this right and otherwise fix it. Thank you!
  const gtPressuraExtAnim = new FontFace(
    "GT Pressura Extended Anim",
    `url("${fontSrcs["GT-Pressura-VF-wght-wdth.woff2"]}") format("woff2-variations"), url("${fontSrcs["GT-Pressura-VF-wght-wdth.ttf"]}") format("truetype-variations")`,
    {
      style: "normal",
    }
  );
  const gtPressuraAllAxesAnim = new FontFace(
    "GT Pressura All Axes Anim",
    `url("${fontSrcs["GT-Pressura-VF-wght-wdth-ital.woff2"]}") format("woff2-variations"), url("${fontSrcs["GT-Pressura-VF-wght-wdth-ital.ttf"]}") format("truetype-variations")`,
    {
      style: "normal",
    }
  );
  const gtPressuraCharset = new FontFace(
    "GT Pressura Charset",
    `url("${fontSrcs["GT-Pressura-VF-wght.woff2"]}") format("woff2-variations"), url("${fontSrcs["GT-Pressura-VF-wght.ttf"]}") format("truetype-variations")`,
    {
      style: "normal",
    }
  );
  gtPressuraProportional.load();
  gtPressuraMono.load();
  gtPressuraExtAnim.load();
  gtPressuraAllAxesAnim.load();
  gtPressuraCharset.load();
  await Promise.all([gtPressuraProportional.loaded, gtPressuraMono.loaded]);
  document.fonts.add(gtPressuraProportional);
  document.fonts.add(gtPressuraMono);
  document.fonts.add(gtPressuraExtAnim);
  document.fonts.add(gtPressuraAllAxesAnim);
  document.fonts.add(gtPressuraCharset);
};

const kickIt = async () => {
  if (isSafari()) {
    document.body.classList.add("is-safari");
  }
  onResize();
  onResize.flush();
  window.addEventListener("resize", onResize);

  // const animatedElements = [...document.querySelectorAll("[class*='anim-']")];
  // animatedElements.forEach((el) => CSSAnimatedElement(el));

  const nav = document.querySelector("nav");
  if (nav) {
    Nav(nav);
  }

  const lottieEls = [
    ...document.querySelectorAll("[data-component='lottie-player']"),
  ];
  lottieEls.forEach((el) => LottiePlayer(el));

  const sections = [...document.querySelectorAll("[data-component='section']")];
  sections.forEach((el) => Section(el));

  const charSetEl = document.querySelector("[data-component='char-set']");
  if (charSetEl) {
    CharSet(charSetEl);
  }

  const familyOverviewTableEls = [
    ...document.querySelectorAll("[data-component='family-overview-table']"),
  ];
  familyOverviewTableEls.forEach((el) => FamilyOverviewTable(el));

  const introStyleOverviewEl = document.querySelector(
    "[data-component='intro-style-overview']"
  );
  if (introStyleOverviewEl) {
    IntroStyleOverview(introStyleOverviewEl);
  }

  const animatedWidthEls = [
    ...document.querySelectorAll("[data-component='animated-width']"),
  ];
  animatedWidthEls.forEach((el) => AnimatedWidth(el));

  const extendedSubfamilyAnimationEl = document.querySelector(
    "[data-component='extended-subfamily']"
  );
  if (extendedSubfamilyAnimationEl) {
    ExtendedSubfamilyAnimation(extendedSubfamilyAnimationEl);
  }

  const typeTesterEl = document.querySelector("[data-component='tt']");
  if (typeTesterEl) {
    TypeTester(typeTesterEl);
  }

  await Promise.all([delay(2000), loadFonts()]);
  document.body.classList.add("is-init");
};

requestAnimationFrame(kickIt);
