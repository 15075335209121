import gsap from "gsap/all";
import { lerp } from "../util/lib";

const ExtendedSubfamilyAnimation = (el) => {
  const value = { t: 0 };
  const onUpdate = () => {
    const wdth = lerp(100, 150, value.t);
    el.style.setProperty("--font-variation-wdth", wdth);
    el.style.setProperty("--slider-translate", `${value.t * 100}%`);
  };

  const animation = gsap.fromTo(
    value,
    { t: 0 },
    { t: 1, ease: "expo.inOut", repeat: -1, yoyo: true, yoyoEase: true, onUpdate, duration: 2 }
  );
  animation.pause();

  const intersectionObserver = new IntersectionObserver((entries) => {
    const { isIntersecting } = entries[0];

    if (isIntersecting) {
      animation.play();
    } else {
      animation.pause();
    }
  });

  intersectionObserver.observe(el);
};
export default ExtendedSubfamilyAnimation;
