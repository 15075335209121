const _temp0 = require("url:./GT-Pressura-Loader.woff2");
const _temp1 = require("url:./GT-Pressura-Mono-VF.ttf");
const _temp2 = require("url:./GT-Pressura-Mono-VF.woff2");
const _temp3 = require("url:./GT-Pressura-VF-Mini.ttf");
const _temp4 = require("url:./GT-Pressura-VF-Mini.woff2");
const _temp5 = require("url:./GT-Pressura-VF-wght-wdth-ital.ttf");
const _temp6 = require("url:./GT-Pressura-VF-wght-wdth-ital.woff2");
const _temp7 = require("url:./GT-Pressura-VF-wght-wdth.ttf");
const _temp8 = require("url:./GT-Pressura-VF-wght-wdth.woff2");
const _temp9 = require("url:./GT-Pressura-VF-wght.ttf");
const _temp10 = require("url:./GT-Pressura-VF-wght.woff2");
const _temp11 = require("url:./GT-Pressura-VF.ttf");
const _temp12 = require("url:./GT-Pressura-VF.woff2");
module.exports = {
  "GT-Pressura-Loader.woff2": _temp0,
  "GT-Pressura-Mono-VF.ttf": _temp1,
  "GT-Pressura-Mono-VF.woff2": _temp2,
  "GT-Pressura-VF-Mini.ttf": _temp3,
  "GT-Pressura-VF-Mini.woff2": _temp4,
  "GT-Pressura-VF-wght-wdth-ital.ttf": _temp5,
  "GT-Pressura-VF-wght-wdth-ital.woff2": _temp6,
  "GT-Pressura-VF-wght-wdth.ttf": _temp7,
  "GT-Pressura-VF-wght-wdth.woff2": _temp8,
  "GT-Pressura-VF-wght.ttf": _temp9,
  "GT-Pressura-VF-wght.woff2": _temp10,
  "GT-Pressura-VF.ttf": _temp11,
  "GT-Pressura-VF.woff2": _temp12
}