// This file controls how Tailwind processes your CSS. For details, see
// https://tailwindcss.com/docs/configuration

module.exports = {
  //
  // WARNING: By default, CodeKit automatically populates the `content` array with all entries from [Project Settings > PurgeCSS]
  // in CodeKit's UI. If you add ANY entries to the `content` array here, CodeKit will not auto-populate the array; it becomes your
  // responsibility to include every type of file in your project that uses CSS rules. It is preferable to edit the PurgeCSS content
  // list in CodeKit's UI.
  //
  // WARNING: DO NOT delete `content` or comment it out. If you do, CodeKit will treat this as a Tailwind 2.x project instead of 3.x.
  //
  content: ["./source/**/*.{html,js}"],

  //
  // All other TailwindCSS options are 100% under your control. Edit this config file as shown in the Tailwind Docs
  // to enable the settings or customizations you need.
  //

  theme: {
    // GT Pressura Break Points
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      xxl: "1536px",
    },

    // GT Pressura Color Scheme
    colors: {
      transparent: "transparent",
      current: "currentColor",
      white: "#ffffff",
      black: "#000000",
      red: "#FD4238",
      gray: "#B5BDBF",
      beige: "#F8F2E4",
      // gray: {
      //   DEFAULT: "#B5BDBF",
      //   dk: "#606060",
      // },
    },

    fontFamily: {
      sans: ["GT Pressura Proportional", "GT Pressura Mono", "Comic Sans MS", "cursive"],
    },

    fontSize: {
      // Add letter-spacing where necessary like this
      // '2xl': ['24px', {
      //   letterSpacing: '-0.01em',
      // }],
      xxs: ["12px", "15px"],
      xs: ["14px", "17px"],
      sm: ["17px", "24px"],
      base: [
        '22px',
        { letterSpacing: '0.015em',
        lineHeight: '30px' },
      ],
      rg: [
        '22px',
        { letterSpacing: '0.015em',
        lineHeight: '30px' },
      ],
      md: ["35px", "32pxpx"],
      lg: ["45px", "54px"],
      xl: ["60px", "60px"],
      "2xl": ["170px", "100%"],
      "3xl": ["12vw", "90%"],
      "4xl": ["20vw", "90%"],
      "5xl": ["30vw", "80%"],
      "6xl": ["40vw", "80%"],
      "7xl": ["60vw", "80%"],
      "8xl": ["80vw", "80%"],
    },

    spacing: {
      px: "1px",
      0: "0",
      1: "1px",
      2: "2px",
      3: "5px",
      4: "10px",
      5: "15px",
      6: "25px",
      7: "50px",
      8: "100px",
      9: "150px",
      10: "300px",
    },

    extend: {
      // For extending any existing themes, i.e. a larger breakpoint
    },
  },

  variants: {},

  //
  // If you want to run any Tailwind plugins (such as 'tailwindcss-typography'), simply install those into the Project via the
  // Packages area in CodeKit, then pass their names (and, optionally, any configuration values) here.
  // Full file paths are not necessary; CodeKit will find them.
  //
  plugins: [require("tailwindcss-crossbrowser-touch")()],
};
