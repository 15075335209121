import TypeTesterSample from "./TypeTesterSample";
import { proportional, mono } from "../../typetester-samples.json";
import { Timeline } from "gsap/gsap-core";
import { getChromeVersion } from "../util/lib";

const MAX_CHROME_VERSION_ANIM = 99;
const TypeTester = (el) => {
  const chromeVersion = getChromeVersion();

  if (chromeVersion && chromeVersion > MAX_CHROME_VERSION_ANIM) {
    el.classList.add("browser-flag-chrome-anim-disable");
  }

  const ttToggles = [
    ...document.querySelectorAll("[data-component='tt__toggle']"),
  ];
  const autopilotBtn = el.querySelector("[data-component='tt__autopilot']");
  const randomizeBtn = el.querySelector("[data-component='tt__randomize']");
  const wghtSliders = [
    ...el.querySelectorAll("[data-component='tt__wght-slider']"),
  ];
  const wdthSliders = [
    ...el.querySelectorAll("[data-component='tt__wdth-slider']"),
  ];
  const italSliders = [
    ...el.querySelectorAll("[data-component='tt__ital-slider']"),
  ];
  const setupButtons = [...el.querySelectorAll("[data-component='tt__setup']")];

  const sampleElProportional = el.querySelector(
    "[data-component='tt__sample'][data-setup='proportional']"
  );
  const sampleElMono = el.querySelector(
    "[data-component='tt__sample'][data-setup='mono']"
  );
  let randomizedSampleIndex = -1;
  const proportionalSamples = proportional.sort(() => Math.random() - 0.5);
  const monoSamples = mono.sort(() => Math.random() - 0.5);
  let isAutopilot = false;

  let currentSetup = "proportional";
  let autopilotTimeline;
  const currentSettings = {
    proportional: { wdth: 0, ital: 0, wght: 0 },
    mono: { wdth: 0, ital: 0, wght: 0 },
  };

  const { refresh: refreshSizeProportional } =
    TypeTesterSample(sampleElProportional);
  const { refresh: refreshSizeMono } = TypeTesterSample(sampleElMono);

  const refreshSize = () => {
    if (currentSetup === "proportional") {
      refreshSizeProportional();
    } else {
      refreshSizeMono();
    }
  };

  const setWdth = (value) => {
    currentSettings[currentSetup].wdth = value;
    el.style.setProperty(`--user-wdth-${currentSetup}`, value);
    wdthSliders.forEach((el) => (el.value = value));
  };

  const setWght = (value) => {
    currentSettings[currentSetup].wght = value;
    el.style.setProperty(`--user-wght-${currentSetup}`, value);
    wghtSliders.forEach((el) => (el.value = value));
  };

  const setItal = (value) => {
    currentSettings[currentSetup].ital = value;
    el.style.setProperty(`--user-ital-${currentSetup}`, value);
    italSliders.forEach((el) => (el.value = value));
  };

  const onWghtUpdate = (e) => {
    setWght(e.target.value);
  };

  const onwdthUpdate = (e) => {
    setWdth(e.target.value);
  };

  const onItalUpdate = (e) => {
    setItal(e.target.value);
  };

  const randomizeSample = () => {
    randomizedSampleIndex++;
    const { text, wdth, wght, ital } =
      currentSetup === "proportional"
        ? proportionalSamples[
            randomizedSampleIndex % proportionalSamples.length
          ]
        : monoSamples[randomizedSampleIndex % monoSamples.length];
    if (currentSetup === "proportional") {
      sampleElProportional.innerHTML = text;
    } else {
      sampleElMono.innerHTML = text;
    }
    setWdth(wdth);
    setWght(wght);
    setItal(ital);
    refreshSize();
  };

  const toggleAutopilot = (value = !isAutopilot) => {
    isAutopilot = value;
    if (isAutopilot) {
      autopilotBtn.classList.add("tt-button-active");

      autopilotTimeline = new Timeline({
        onComplete: () => {
          autopilotTimeline.seek("start");
        },
        onUpdate: () => {
          setWdth(currentSettings[currentSetup].wdth);
          setWght(currentSettings[currentSetup].wght);
          setItal(currentSettings[currentSetup].ital);
        },
      });

      if (currentSetup === "proportional") {
        autopilotTimeline.to(currentSettings[currentSetup], 1, {
          wght: 300,
          wdth: 100,
          ital: 0,
          ease: "power3.inOut",
        });
        autopilotTimeline.addLabel("start");
        autopilotTimeline.to(currentSettings[currentSetup], 1, {
          wght: 900,
          wdth: 100,
          ital: 0,
          ease: "power3.inOut",
        });
        autopilotTimeline.to(currentSettings[currentSetup], 1, {
          wght: 900,
          wdth: 150,
          ital: 0,
          ease: "power3.inOut",
        });
        autopilotTimeline.to(currentSettings[currentSetup], 1, {
          wght: 900,
          wdth: 150,
          ital: 1,
          ease: "power3.inOut",
        });
        autopilotTimeline.to(currentSettings[currentSetup], 1, {
          wght: 300,
          wdth: 100,
          ital: 0,
          ease: "power3.inOut",
        });
      } else {
        autopilotTimeline.to(currentSettings[currentSetup], 1, {
          wght: 300,
          ital: 0,
          ease: "power3.inOut",
        });
        autopilotTimeline.addLabel("start");
        autopilotTimeline.to(currentSettings[currentSetup], 1, {
          wght: 900,
          ital: 0,
          ease: "power3.inOut",
        });
        autopilotTimeline.to(currentSettings[currentSetup], 1, {
          wght: 900,
          ital: 1,
          ease: "power3.inOut",
        });
        autopilotTimeline.to(currentSettings[currentSetup], 1, {
          wght: 300,
          ital: 0,
          ease: "power3.inOut",
        });
        autopilotTimeline.timeScale(1.12);
      }
    } else {
      autopilotTimeline?.kill();
      autopilotBtn.classList.remove("tt-button-active");
      el.classList.remove("tt--autopilot-active");
      refreshSize();
    }
  };

  const onSetupClick = (e) => {
    toggleAutopilot(false);
    setupButtons.forEach((sibling) => {
      sibling.disabled = false;
      sibling.classList.remove("tt-button-active");
      sibling.classList.add("tt-button-inactive");
    });
    e.target.classList.add("tt-button-active");
    e.target.classList.remove("tt-button-inactive");
    e.target.disabled = true;
    currentSetup = e.target.dataset.setup;
    el.dataset.setup = currentSetup;
    randomizeSample();
  };

  const toggleVisibility = () => {
    toggleAutopilot(false);
    document.body.classList.toggle("tt-active");
    refreshSize();
  };

  ttToggles.forEach((el) => {
    el.addEventListener("click", toggleVisibility);
  });

  wghtSliders.forEach((el) => {
    setWght(el.value);
    el.addEventListener("input", onWghtUpdate);
    el.addEventListener("pointerup", refreshSize);
  });
  wdthSliders.forEach((el) => {
    setWdth(el.value);
    el.addEventListener("input", onwdthUpdate);
    el.addEventListener("pointerup", refreshSize);
  });
  italSliders.forEach((el) => {
    setItal(el.value);
    el.addEventListener("input", onItalUpdate);
    el.addEventListener("pointerup", refreshSize);
  });
  setupButtons.forEach((button) => {
    button.addEventListener("click", onSetupClick);
  });

  randomizeBtn.addEventListener("click", randomizeSample);
  autopilotBtn.addEventListener("click", () => toggleAutopilot());
  randomizeSample();
};

export default TypeTester;
