import gsap from "gsap/all";
import { getScrollTop, lerp } from "../util/lib";
import { debounce, clamp } from "lodash";

const ANIM_MARGIN_PCT = 65;
const MIN_WGHT = 300;
const MAX_WGHT = 900;

const AnimatedWidth = (el) => {
  let min = 0,
    max = 0;
  let raf;

  const onResize = debounce(() => {
    const margin = Math.round((window.innerHeight / 100) * ANIM_MARGIN_PCT);
    const st = getScrollTop();
    const rect = el.getBoundingClientRect();
    min = rect.top + st - window.innerHeight + margin;
    max = min + rect.height + window.innerHeight - 62 - margin;
  }, 333);

  // const sampleEls = [
  //   ...el.querySelectorAll('[data-component="animated-width__sample"]'),
  // ];
  const letters = [
    ...el.querySelectorAll("[data-component='animated-width__letter']"),
  ];
  const hrs = [...el.querySelectorAll("[data-component='animated-width__hr']")];

  const update = (t) => {
    letters.forEach((letterEl) => {
      const minWidth = parseInt(letterEl.dataset.minWidth);
      const maxWidth = parseInt(letterEl.dataset.maxWidth);
      const currentWidth = lerp(minWidth, maxWidth, t);
      letterEl.style.setProperty(
        "--width-val",
        `" ${Math.round(currentWidth)} "`
      );
      const currentWght = lerp(MIN_WGHT, MAX_WGHT, t);
      letterEl.style.setProperty("--font-variation-wght", currentWght);
    });

    hrs.forEach((hrEl) => {
      const minWidth = parseFloat(hrEl.dataset.from);
      const maxWidth = parseFloat(hrEl.dataset.to);
      const currentTop = lerp(minWidth, maxWidth, t);
      hrEl.style.setProperty("--top", `${currentTop}%`);
    });
  };

  const animate = () => {
    const st = getScrollTop();
    const t = clamp(lerp(0, 1, (st - min) / (max - min)), 0, 1);
    update(t);
    raf = requestAnimationFrame(animate);
  };

  onResize();
  onResize.flush();
  window.addEventListener("resize", onResize);

  const intersectionObserver = new IntersectionObserver((entries) => {
    const { isIntersecting } = entries[0];
    if (isIntersecting) {
      onResize();
      onResize.flush();
      cancelAnimationFrame(animate);
      raf = requestAnimationFrame(animate);
    } else {
      cancelAnimationFrame(raf);
    }
  });
  intersectionObserver.observe(el);

  // const animations = sampleEls.map((sampleEl) => {
  //   const letters = [
  //     ...sampleEl.querySelectorAll("[data-component='animated-width__letter']"),
  //   ];

  //   const MIN_WGHT = 100;
  //   const MAX_WGHT = 900;
  //   const duration = 3;
  //   const value = { t: 0 };

  // const onUpdate = () => {
  //   letters.forEach((letterEl) => {
  //     const minWidth = parseInt(letterEl.dataset.minWidth);
  //     const maxWidth = parseInt(letterEl.dataset.maxWidth);
  //     const currentWidth = lerp(minWidth, maxWidth, value.t);
  //     letterEl.style.setProperty(
  //       "--width-val",
  //       `" ${Math.round(currentWidth)} "`
  //     );
  //     const currentWght = lerp(MIN_WGHT, MAX_WGHT, value.t);
  //     letterEl.style.setProperty("--font-variation-wght", currentWght);
  //   });
  // };

  //   const anim = gsap.fromTo(
  //     value,
  //     { t: 0 },
  //     {
  //       t: 1,
  //       duration: 3,
  //       repeat: -1,
  //       yoyo: true,
  //       ease: "cubic.inOut",
  //       onUpdate,
  //     }
  //   );
  //   anim.seek(duration * 0.5); // set starting point
  //   anim.pause();
  //   onUpdate();
  //   return anim;
  // });
  // el.addEventListener("mouseenter", () => {
  //   animations.forEach((anim) => anim.play());
  // });

  // el.addEventListener("mouseleave", () => {
  //   animations.forEach((anim) => anim.pause());
  // });
};

export default AnimatedWidth;
