import { debounce, throttle } from "lodash";
import { getScrollTop } from "../util/lib";

const Nav = (el) => {
  const sections = [...document.querySelectorAll("[data-component='section']")];

  let offsets = [];
  const styles = sections.map((el) => {
    const computedStyle = window.getComputedStyle(el);
    const backgroundColor = computedStyle.getPropertyValue("background-color");
    const color = computedStyle.getPropertyValue("color");
    return [backgroundColor, color];
  });

  const onResize = debounce(() => {
    const st = getScrollTop();
    sections.forEach((el) => (el.style.position = "unset"));
    offsets = sections.map((el) => {
      const rect = el.getBoundingClientRect();
      return rect.top + st - 62;
    });
    sections.forEach((el) => (el.style.position = ""));
  }, 333);

  const onScroll = throttle(() => {
    const st = getScrollTop();

    if (st > offsets[1]) {
      el.classList.add("nav--is-visible");
    } else {
      el.classList.remove("nav--is-visible");
    }

    for (let i = offsets.length - 1; i >= 0; i--) {
      if (st > offsets[i]) {
        document.body.style.setProperty(
          "--section-background-color",
          styles[i][0]
        );
        document.body.style.setProperty("--section-text-color", styles[i][1]);
        return;
      }
    }
    document.body.style.setProperty(
      "--section-background-color",
      "rgb(255,255,255)"
    );
    document.body.style.setProperty("--section-text-color", "rgb(0,0,0)");
  }, 32);

  onResize();
  onResize.flush();
  onScroll();
  onScroll.flush();
  window.addEventListener("resize", onResize);
  document.body.addEventListener("scroll", onScroll);

  sections.forEach((el) => {
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(el);
  });
};

export default Nav;
