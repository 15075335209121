const CSSAnimatedElement = (el) => {
  const intersectionObserver = new IntersectionObserver((entries) => {
    const { isIntersecting } = entries[0];
    if (isIntersecting) {
      el.style.animationPlayState = "running";
    } else {
      el.style.animationPlayState = "paused";
    }
  });

  intersectionObserver.observe(el);
};

export default CSSAnimatedElement;
